<template>
    <div class="flex justify-between mb-4">
        <div
            class="btn flex items-center border rounded-full text-xs cursor-pointer"
            :class="[ ! selectedStatus ? 'bg-green-100 border-green-200' : 'bg-blue-100' ]"
            @click="changeStatus('')"
        >
            <p class="mr-1">
                {{ $t(`economy.all`) }}
            </p>
            <span
                class="flex items-center opacity-75 rounded-full py-1 px-2 text-xs"
                :class="[ ! selectedStatus ? 'bg-green-200' : 'bg-blue-200' ]"
            >{{ invoiceNumbers.all }}</span>
        </div>
        <div
            class="btn flex items-center border rounded-full text-xs cursor-pointer"
            :class="[ selectedStatus == 'draft' ? 'bg-green-100 border-green-200' : 'bg-blue-100' ]"
            @click="changeStatus('draft')"
        >
            <p class="mr-1">
                {{ $t(`economy.draft`) }}
            </p>
            <span
                class="flex items-center opacity-75 rounded-full py-1 px-2 text-xs"
                :class="[ selectedStatus == 'draft' ? 'bg-green-200' : 'bg-blue-200' ]"
            >{{ invoiceNumbers.draft }}</span>
        </div>
        <div
            class="btn flex items-center border rounded-full text-xs cursor-pointer"
            :class="[ selectedStatus == 'confirmed' ? 'bg-green-100 border-green-200' : 'bg-blue-100' ]"
            @click="changeStatus('confirmed')"
        >
            <p class="mr-1">
                {{ $t(`economy.confirmed`) }}
            </p>
            <span
                class="flex items-center opacity-75 rounded-full py-1 px-2 text-xs"
                :class="[ selectedStatus == 'confirmed' ? 'bg-green-200' : 'bg-blue-200' ]"
            >{{ invoiceNumbers.confirmed }}</span>
        </div>
        <div
            class="btn flex items-center border rounded-full text-xs cursor-pointer"
            :class="[ selectedStatus == 'sent' ? 'bg-green-100 border-green-200' : 'bg-blue-100' ]"
            @click="changeStatus('sent')"
        >
            <p class="mr-1">
                {{ $t(`economy.sent`) }}
            </p>
            <span
                class="flex items-center opacity-75 rounded-full py-1 px-2 text-xs"
                :class="[ selectedStatus == 'sent' ? 'bg-green-200' : 'bg-blue-200' ]"
            >{{ invoiceNumbers.sent }}</span>
        </div>
        <div
            class="btn flex items-center border rounded-full text-xs cursor-pointer"
            :class="[ selectedStatus == 'unpaid' ? 'bg-green-100 border-green-200' : 'bg-blue-100' ]"
            @click="changeStatus('unpaid')"
        >
            <p class="mr-1">
                {{ $t(`economy.unpaid`) }}
            </p>
            <span
                class="flex items-center opacity-75 rounded-full py-1 px-2 text-xs"
                :class="[ selectedStatus == 'unpaid' ? 'bg-green-200' : 'bg-blue-200' ]"
            >{{ invoiceNumbers.unpaid }}</span>
        </div>
        <div
            class="btn flex items-center border rounded-full text-xs cursor-pointer"
            :class="[ selectedStatus == 'paid' ? 'bg-green-100 border-green-200' : 'bg-blue-100' ]"
            @click="changeStatus('paid')"
        >
            <p class="mr-1">
                {{ $t(`economy.paid`) }}
            </p>
            <span
                class="flex items-center opacity-75 rounded-full py-1 px-2 text-xs"
                :class="[ selectedStatus == 'paid' ? 'bg-green-200' : 'bg-blue-200' ]"
            >{{ invoiceNumbers.paid }}</span>
        </div>
        <div
            class="btn flex items-center border rounded-full text-xs cursor-pointer"
            :class="[ selectedStatus == 'rut_sent' ? 'bg-green-100 border-green-200' : 'bg-blue-100' ]"
            @click="changeStatus('rut_sent')"
        >
            <p class="mr-1">
                {{ $t(`economy.rut_sent`) }}
            </p>
            <span
                class="flex items-center opacity-75 rounded-full py-1 px-2 text-xs"
                :class="[ selectedStatus == 'rut_sent' ? 'bg-green-200' : 'bg-blue-200' ]"
            >{{ invoiceNumbers.rut_sent }}</span>
        </div>
        <div
            class="btn flex items-center border rounded-full text-xs cursor-pointer"
            :class="[ selectedStatus == 'completed' ? 'bg-green-100 border-green-200' : 'bg-blue-100' ]"
            @click="changeStatus('completed')"
        >
            <p class="mr-1">
                {{ $t(`economy.completed`) }}
            </p>
            <span
                class="flex items-center opacity-75 rounded-full py-1 px-2 text-xs"
                :class="[ selectedStatus == 'completed' ? 'bg-green-200' : 'bg-blue-200' ]"
            >{{ invoiceNumbers.completed }}</span>
        </div>
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    computed: {
        invoiceNumbers() {
            return this.$store.state.economy.invoicesNumbers;
        },
        selectedStatus() {
            return this.$store.state.economy.selectedStatus;
        },
    },

    created() {
        const { status } = queryString.parse(window.location.search);
        this.$store.commit('economy/updateSelectedStatus', status);
    },

    methods: {
        changeStatus(status) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.status = status;
            this.$router.replace({ query }).catch(() => {});
            this.$store.commit('economy/updateSelectedStatus', status);
            this.$emit('statusChanged', status);
        },
    },
};
</script>
<style scoped>
.btn {
    padding: 2px 2px 2px 10px;
}
</style>
